body {
    background-color: #bebebe;
}

.navi {
    float: right;
    margin-left: auto;
}

.navbar {
    background-color: rgb(255, 255, 255);
}

.logo {
    width: 200px;
}

/* .homepage-main {
    background-image: url("../public/img/customer-credit-card-bw.jpg");
    background-size: 100% auto;
    filter: brightness(50%);
    border-radius: 30px;
    padding: 50px;
    padding-left: 50px;
} */

.background-video {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(68%);
    max-height: 600px;
}

#video-text {
    position: absolute;
    top: 150px;
}

.white-text {
    color: white;
}

.colored-text {
    color: #4CB5BE;
}

#homepage-colored-header {
    font-size: 40px;
}

.main-btn {
    background-color: #4CB5BE;
    color: white;
}

.main-btn:hover {
    background-color: #358086;
    color: white;
}

.homepage-box {
    /* background-color: #4CB5BE; */
    border-radius: 30px;
    /* padding: 20px; */
    height: 570px;
    position: relative;
    background-color: white;
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        /* 0 41.8px 33.4px rgba(0, 0, 0, 0.086), */
        0 100px 80px rgba(0, 0, 0, 0.12);
}

.homepage-box h3 {
    font-size: 25px;
}

.homepage-box p {
    font-size: 17px;
}

.homepage-box button {
    font-size: 19px;
}

/* .boxes-row {
    display: flex;
    margin: auto;
} */

.homepage-box-pic {
    /* width: 295px; */
    border-radius: 30px 30px 0px 0px;
    
}

.box-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 15px 8px 15px;
    color:#4CB5BE;
    background-color: white;
    outline: 1px solid #4CB5BE;
    border-radius: 15px;
}

.secondary-button {
    /* padding-left: 15px;
    padding-right: 15px;
    color:#4CB5BE;
    background-color: white;
    outline: 1px solid #4CB5BE */
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 15px 8px 15px;
    color:#4CB5BE;
    background-color: white;
    outline: 1px solid #4CB5BE;
    border-radius: 15px;
}

.secondary-button:hover {
    background-color: #4CB5BE;
    color: white;
}

.hover-white-text:hover {
    color: white;
}

.tertiary-button {
    padding-left: 15px;
    padding-right: 15px;
    color:white;
    background-color: #4CB5BE;
    outline: 1px solid white
}

.box-button:hover {
    background-color: #4CB5BE;
    color: white;
}

.header {
    height: 500px;
}

.header-vertical-align {
    line-height: 500px;
}

span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

.footer {
    background-color: #8a8a8a;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

/* .logos-row {
    height: 200px;
    line-height: 200px;
} */

.logos {
    /* width: 250px; */
    /* height: 150px; */
    width: 100%;
    object-fit: contain;
}

/* #tonic-icon {
    width: 120px;
} */

/* .icon-div {
    width: 250px;
} */

.round-image {
    border-radius: 50%;
    width: 350px;
    height: 350px;
    object-fit: cover;
}

.center-header {
    margin: auto;
}

.rounded-borders {
    border-radius: 30px;
}

.icon {
    width: 40px;
}

.med-img {
    width: 400px;
    object-fit: cover;
}

.small-img {
    width: 300px;
    object-fit: cover;
}

.med-text {
    font-size: 18px;
}
 
.tab-box {
    background-color: #4cb5be;
    margin: auto;
    /* width: 1000px; */
    margin-top: 50px;
    padding-bottom: 20px;
}

.tabs {
    display: flex;
    justify-content: center;
}
 
.tab {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    border-bottom: none;
    background-color: #4a9199;
    border-radius: 15px 15px 0px 0px;
    padding: 10px 20px 10px 20px;
    color: white;
    font-weight: 100;
    width: 100%;
    text-align: center;
}
 
.tab.active {
    background-color: #ffffff;
    color: #4cb5be;
    font-weight: 700;
}
 
.middle-tab {
    margin: 0px 10px 0px 10px;
}

.tab-content {
    border: 1px solid #ddd;
    border-top: none;
    padding: 10px;
    border-radius: 0px 0px 30px 30px;
    background-color: #ffffff;
}

.colored-bg {
    background-color: #4a9199;
}

.header img {
    height: 500px;
    object-fit: cover;
}

/* .set-width-400 {
    width: 400px;
    padding: 0px;
}

.set-width-800 {
    width: 800px;
}

.set-width-1000 {
    width: 1000px;
} */

.object-fit-30 {
    height: 300px;
    width: 30%;
    object-fit: cover;
}

.dark-blue-text {
    color: #4a9199;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}


.floating-image {
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        /* 0 41.8px 33.4px rgba(0, 0, 0, 0.086), */
        0 100px 80px rgba(0, 0, 0, 0.12);
    max-width: 900px;
}

.floating-image-no-shadow {
    max-width: 900px;
}

.part-colored-bg {
    background: linear-gradient(
        to bottom,
        #4a9199 0%,
        #4a9199 60%,
        #f8f9fa 60%,
        #f8f9fa 100%
    )
}

.orange-text {
    color: orange;
}

.orange-bg {
    background-color: rgb(231, 99, 11);
}

.light-orange-bg {
    background-color: orange;;
}

.dark-orange-text {
    color: rgb(231, 99, 11);
}

.orange-button-hover:hover {
    background-color: rgb(193, 85, 12);
    color: white;
}

.subTabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.subTab {
    cursor: pointer;
    /* padding: 10px; */
    /* margin-right: 10px; */
    /* border: 1px solid #ddd; */
    /* border-bottom: none; */
    /* background-color: #4a9199; */
    border-radius: 50px;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    color: black;
    font-weight: 100;
    text-align: center;
    /* background-color: lightgray;    */
}

.subTab-labels {
    background-color: lightgray;
    border-radius: 50px;

}

.subTabs-container {
    /* background-color: lightgray; */
    border-radius: 30px;
    /* padding: 20px 10px 20px 10px; */
    justify-content: center;
    /* padding: 0px 15px 0px 15px; */
}

.subTab-content {
    /* border: 1px solid #ddd; */
    /* border-top: none; */
    padding: 10px;
    /* border-radius: 0px 0px 30px 30px; */
    background-color: #ffffff;
}

.subTab.active {
    background-color: #4cb5be;
    color: #ffffff;
    font-weight: 500;
    /* vertical-align: middle; */
}

.object-fit {
    object-fit: cover;
}

.pointer {
    cursor: pointer;
}

button:hover.orange-text{
    color:rgb(231, 99, 11);
}

.light-gray-bg {
    background-color: #d6d6d6;
}

.floating-absolute {
    position: absolute;
    top: 180px;
    left: 46%;
}

.relative-parent {
    position: relative;
}

.header-floating-screen {
    height: 450px;
    width: auto;
}

.vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;}

table {
    border: 1px solid #ccc;
}

td, th {
    border-top-width: 1px;
    border-top-style: solid; /* double, dashed, dotted... */
    border-top-color: #ccc;
    border-left-width: 1px;
    border-left-style: solid; /* double, dashed, dotted... */
    border-left-color: #ccc;
    border-right-width: 1px;
    border-right-style: solid; /* double, dashed, dotted... */
    border-right-color: #ccc;
}
